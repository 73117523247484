<style>
@import '~@/assets/css/common.css';
@import '~@/assets/css/index.css';
@import '~@/assets/css/list.css';
</style>
<style type="text/css" scoped>
.center-list-title .iconfont {
  color: #2886bd;
}
.news-main {
  min-height: 550px;
}
.div_enrollList_tag {
  width: auto !important;
  padding-left: 5px;
  padding-right: 5px;
}
</style>
<script>
import $ from 'jquery'
import Header from './index-header.vue'
import Footer from './index-footer.vue'
import Navbar from './navbar.vue'
import commonPagination from '../common/commonPagination'
import { getSecondList } from '@/api/web/web.js'

import { getAllticket } from '@/api/examination'

/**
 * Dashboard component
 */
export default {
  components: {
    Header,
    Navbar,
    Footer
  },
  computed: {},

  data() {
    return {
      zkzList: [],
      time: '', //年份
      timeList: []
    }
  },
  methods: {
    /**
     * 获取考试二级分类
     */
    getList() {
      getSecondList().then((res) => {
        if (res.status) {
          let list = res.data
          this.secondList = list
        }
      })
    },

    /**
     * 跳转考试计划
     */
    goToPlan(flbm, flmc) {
      let routeUrl = this.$router.resolve({
        path: '/examPlan',
        query: {
          flbm: flbm,
          flmc: flmc
        }
      })
      window.open(routeUrl.href, '_selft')
    },
    openModal(index) {
      var _this = this
      let data = _this.zkzList[index]
      let xywdl = data.sfyxwdl
      if (!xywdl || xywdl == 0) {
        window.location.href =
          'https://www.schrss.org.cn/ssologin/login?service=https%3A%2F%2Fersuserapi.jdpta.com%2Fyethan%2Flogin%2Fcas'
        return
      }
      let routeData = this.$router.resolve({
        path: '../ticketSearch',
        query: {
          mbh: data.mbh,
          sid: data.sid,
          sfkwks: data.sfkwks
        }
      })
      window.open(routeData.href, '_blank')
    },
    getticketList(ksnf) {
      getAllticket({ ksnf: 'all' }).then((res) => {
        if (res.status) {
          let newTime = new Date().getTime()
          let list = res.data || []
          list.forEach((v) => {
            if (
              v.kfrq &&
              new Date(v.kfrq.replace(/-/g, '/')).getTime() < newTime
            ) {
              if (
                v.jsrq &&
                new Date(v.jsrq.replace(/-/g, '/')).getTime() > newTime
              ) {
                v.canPrint = true
              } else if (!v.jsrq) {
                v.canPrint = true
              }
            }
          })
          this.zkzList = list.filter((v) => {
            // 是否允打印
            return v.sfyxdy == 1 && v.canPrint
          })
        }
      })
    },
    change() {
      this.getticketList(this.time)
    }
  },
  mounted() {
    let year = new Date().getFullYear()
    this.timeList = [year, year - 1, year - 2, year - 3, year - 4]
    this.time = year
    this.getticketList(year)
  },
  created() {
    this.getList()
  }
}
</script>

<template>
  <div>
    <Header></Header>
    <div class="main">
      <div class="wp">
        <Navbar></Navbar>
        <div class="white-bg">
          <div>
            <div class="row">
              <div class="col-lg-12">
                <div class="card" style="min-height: 470px">
                  <div class="card-body">
                    <h4 class="card-title pb-3 border-dash">
                      <span class="mr-2">打印准考证项目列表</span>
                      <!-- <el-select
                        v-model="time"
                        placeholder="请选择年份"
                        style="min-width: 6%"
                        size="small"
                        class="m-2 w-10"
                        @change="change"
                      >
                        <el-option
                          v-for="item in timeList"
                          :key="item"
                          :label="item"
                          :value="item"
                        >
                        </el-option>
                      </el-select> -->
                    </h4>
                    <div class="table-responsive border mt-3">
                      <table
                        class="table table-has-bg light-table table-hover table-bordered"
                      >
                        <thead class="thead-light">
                          <tr>
                            <th style="width: 4%">序号</th>
                            <th>考试名称</th>
                            <!-- <th style="width: 20%">准考证名称</th> -->
                            <th style="width: 300px">打印日期</th>
                            <th style="width: 150px">操作</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in zkzList" :key="index">
                            <th scope="row">{{ index + 1 }}</th>
                            <td>
                              <a href="" class="blue-font line2">
                                {{ item.ksmc }}
                              </a>
                            </td>
                            <!-- <td>{{ item.zkzmc }}</td> -->
                            <td>
                              {{ item.kfrq || '--' }}
                              <span class="m-1">至</span>
                              {{ item.jsrq || '--' }}
                            </td>

                            <td style="text-align: center">
                              <b-button
                                variant="info"
                                class="h30 w-md"
                                v-if="item.canPrint"
                                @click="openModal(index)"
                                style="width: 120px"
                              >
                                <i class="iconfont mr-2 icon-printer"></i>
                                {{ item.sfyxwdl == 1 ? '打印' : '登录打印' }}
                              </b-button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div
                        v-if="zkzList.length == 0"
                        class="d-flex justify-content-center mt-3"
                        style="min-height: 200px"
                      >
                        暂无准考证打印信息
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
